<template>
  <div>
    <template v-if="clicked">
      <v-text-field
        v-model.lazy="valor"
        v-money="vMoney"
        autofocus
        outlined
        class="fields pb-0"
        height="20px"
        dense
        hide-details
        :disabled="disabledFields || disabled"
        :counter="counter"
        :placeholder="text ? '' : '0'"
        :prefix="money ? 'R$' : ''"
        :suffix="percentage ? '%' : ''"
        :value="valor"
        :maxlength="!text ? (money ? 7 : 6) : counter"
        @keydown="$event.key === '-' ? $event.preventDefault() : null"
        @keydown.tab.exact.prevent="toggleNext(0, 1)"
        @keydown.shift.tab.exact.prevent="toggleNext(0, -1)"
        @keydown.enter.exact.prevent="toggleNext(1, 0)"
        @keydown.shift.enter.exact.prevent="toggleNext(-1, 0)"
        @blur="toggleEdit"
      />
    </template>
    <v-tooltip v-else top :disabled="disabledTooltip">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" class="field-padding" :class="disabled ? 'field-disabled' : ''" v-on="on" @click="toggleEdit">
          <span v-if="money" class="px-0">
            <span v-if="valor && valor !== '0,00'">R$ {{ valor | localeString(precisionMoney) }}</span>
            <span v-else>{{ emptyMoneyLabel }}</span>
          </span>
          <span v-else-if="percentage" class="px-0">
            <span v-if="valor && valor !== '0'">{{ valor }}%</span>
            <span v-else>-</span>
          </span>
          <span v-else>{{ valor }}</span>
        </span>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { VMoney } from 'v-money'
export default {
  name: 'FieldToggle',
  directives: { money: VMoney },
  props: {
    value: {
      default: ''
    },
    money: {
      type: Boolean,
      default: false
    },
    percentage: {
      type: Boolean,
      default: false
    },
    defaultNumber: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledFields: {
      type: Boolean,
      default: false
    },
    counter: {
      type: Number,
      default: 1000
    },
    row: {
      type: Number,
      default: 0
    },
    column: {
      type: Number,
      default: 0
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    },
    limitValue: {
      type: Number,
      default: 0
    },
    emptyMoneyLabel: {
      type: String,
      default: '-'
    },
    isIndustryCampaign: {
      type: Boolean,
      default: false
    },
    precisionMoney: {
      type: Number,
      default: 2
    }
  },
  data: () => ({
    clicked: false,
    localValue: 0
  }),
  computed: {
    vMoney() {
      if (this.money) return { decimal: ',', thousands: '.', precision: this.precisionMoney, masked: false }
      if (this.percentage) return { decimal: ',', thousands: '.', precision: 0, masked: false }
      if (this.defaultNumber) return { decimal: ',', thousands: '.', precision: 0, masked: false }
      return false
    },
    valor: {
      get() {
        return this.localValue
      },
      set(value) {
        this.localValue = value
        if (this.money || this.percentage || this.defaultNumber) {
          let v = value
          if (!value) return 0

          v = String(v)
            .split('.')
            .join('')
            .split(',')
            .join('.')
          if (v.length > 7) return false

          this.$emit('input', Number(v.replace(/[^0-9-.]/g, '')) > 0 ? Number(v.replace(/[^0-9.]/g, '')) : 0)
        } else if (this.text) {
          this.$emit('input', value)
        } else {
          let MinAndMaxValue = parseFloat(
            value
              .toString()
              .replace(/[^0-9-.,]/g, '')
              .replace(',', '.')
          )
          if (isNaN(MinAndMaxValue)) MinAndMaxValue = 0
          this.$emit('input', MinAndMaxValue)
        }
      }
    },

    disabledTooltip() {
      if (this.isIndustryCampaign) {
        return !this.disabled
      } else {
        return this.disabled || !this.tooltip || !this.tooltipText
      }
    }
  },
  watch: {
    value(value) {
      this.localValue = value
    }
  },
  mounted() {
    this.valor = this.value
  },
  beforeUnmount() {
    this.$bus.$off(`toggle-${this.row}-${this.column}`)
  },
  methods: {
    toggleEdit() {
      if (this.disabled) return
      this.clicked = !this.clicked
      if (this.limitValue !== 0) {
        if (this.valor.toString().length > 3) {
          this.valor = this.limitValue
        } else {
          this.valor = this.valor > this.limitValue ? this.limitValue : this.valor
        }
      }
      if ((this.money || this.percentage || this.defaultNumber) && this.valor < 0) {
        this.valor = 0
      }
    },
    toggleNext(deltaX, deltaY) {
      this.$bus.$emit(`toggle-${this.row + deltaX}-${this.column + deltaY}`, {})
    }
  }
}
</script>

<style lang="scss" scoped>
.field-padding {
  span {
    display: flex;
    padding: 0 5px;
    width: 100%;
    height: 40px !important;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid rgba(3, 3, 3, 0);
    white-space: normal;
    font-size: 14px;

    &:hover {
      border: 1px solid rgb(217, 217, 217, 1);
    }
  }
}
.field-disabled {
  span {
    &:hover {
      border-color: transparent !important;
      cursor: text !important;
    }
  }
}
.v-input {
  font-size: 14px;
}
.v-input__slot {
  min-height: 22px !important;
}
</style>
